<template>
  <div>
    <b-row>
      <b-col class="text-center">
        <LogoPCXPro />
      </b-col>
    </b-row>
    <b-row class="mb-2 mt-2 ">
      <b-col class="text-center">
        <span
          class="modal-title"
          style="color: #7475BA;"
        ><strong> Primeira Caixa está de cara nova!</strong></span>
      </b-col>
    </b-row>
    <b-row class="mb-2 mt-2 justify-content-center align-items-center">
      <b-col
        cols="12"
        sm="12"
        md="6"
        lg="4"
        class="text-center"
      >
        <b-img
          src="@/assets/images/pages/deviceMacbookAirmacbook.png"
          fluid
          alt="Primeira Caixa Profissionais"
        />
      </b-col>
      <b-col
        cols="12"
        lg="5"
        md="6"
        sm="12"
        class="text-start mt-1"
      >
        <span
          class="modal-font"
          style="color: #7475BA;"
        >A partir do dia <strong>29/05</strong> a <strong>funcionalidade de liberação de itens</strong> irá evoluir para a plataforma <strong>Primeira Caixa Profissionais</strong>, uma versão mais fácil e moderna!</span>
        <b-row class="mb-2">
          <b-col class="text-start mt-2">
            <b-button
              class="color-pcx"
              @click="goToPCXPro"
            >
              Acesse agora!
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="text-center">
        <p>Em caso de dúvidas, entre em contato com nosso <b-link
          href="https://wa.me/5511971634637"
          class="suport-link"
        >
          suporte.
        </b-link></p>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow, BCol, BButton, BImg, BLink,
} from 'bootstrap-vue'
import LogoPCXPro from './LogoPCXPro.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    LogoPCXPro,
    BImg,
    BLink,
  },
  props: {
    pcxProLink: {
      type: String,
      default: '',
    },
  },
  methods: {
    goToPCXPro() {
      window.location.href = this.pcxProLink
    },
  },
}
</script>
<style>

.modal-font {
  font-size: 20px;
}

.modal-title {
  font-size: 30px;
}

.color-pcx {
  background-color: #7475BA !important;
  border-color: #7475BA !important;
  color: white !important;
}

.suport-link {
  text-decoration: underline;
  color: #767676;
}

.suport-link:hover {
  color: #767676
}
</style>
