<template>
  <validation-observer ref="scoreRules">
    <b-modal
      id="modal-csat"
      v-model="show"
      size="lg"
      title="Pesquisa de satisfação"
      centered
      no-close-on-backdrop
      no-close-on-esc
      hide-footer
      @close="cancel"
    >
      <b-container>
        <b-row>
          <b-col class="text-center mb-1">
            <p>
              <strong>O que você achou da experiência de liberar um item para seu paciente?</strong>
            </p>
          </b-col>
        </b-row>
        <validation-provider
          #default="{ errors }"
          ref="scoreInput"
          name="score"
          rules="requiredScore"
        >
          <b-row class="justify-content-center">
            <div
              v-for="option in options"
              :key="option.value"
              class="text-center"
            >
              <b-button
                variant="link"
                size="sm"
                @click="selectedScore(option.value)"
              >
                <b-img
                  :src="imageUrl(option)"
                  alt="Radio Option"
                  width="28"
                  class="img-fluid d-sm-none"
                />
                <b-img
                  :src="imageUrl(option)"
                  alt="Radio Option"
                  class="img-fluid d-none d-sm-block"
                />
              </b-button>
              <p
                style="font-size: 11px;"
                class="d-sm-none"
              >
                {{ option.text }}
              </p>
              <p
                style="font-size: 11px;"
                class="d-none d-sm-block"
              >
                {{ option.text }}
              </p>
            </div>
          </b-row>
          <b-row>
            <small
              v-if="errors.length > 0"
              class="text-danger"
            >{{ errors[0] }}</small>
          </b-row>
        </validation-provider>
        <div v-if="selectedOption">
          <b-row>
            <b-col
              class="text-center mt-2"
              cols="12"
            >
              <p>
                <strong>Fale para a gente sua opinião.</strong> (opcional)
              </p>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group
                label-for="textarea"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="maxCaracters"
                  name="celular"
                >
                  <b-form-textarea
                    id="textarea"
                    v-model="comment"
                    :class="errors.length > 0 ? 'text-danger' : ''"
                    placeholder="Insira aqui seu comentário."
                    rows="5"
                    size="lg"
                    :state="errors.length > 0 ? false:null"
                  />
                  <div :class=" errors.length > 0 ? 'text-danger text-right':'text-right text-muted'">
                    {{ comment.length }} / {{ maxCharacters }}
                  </div>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-checkbox
                id="checkbox-new-features"
                v-model="newFeatures"
                name="checkbox-new-features"
                value="true"
                unchecked-value="false"
              >
                <strong>Quero testar novas funcionalidades.</strong>
              </b-form-checkbox>
            </b-col>
          </b-row>
        </div>
        <div
          v-if="newFeatures == 'true'"
          class="mt-2"
        >
          <b-row>
            <b-col>
              <p>Experimente as novas funcionalidades em primeira mão! Seja voluntário e faça parte da inovação enquanto melhoramos sua experiência.</p>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form>
                <b-row>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="nome"
                      >
                        <b-form-input
                          v-model="name"
                          placeholder="Nome"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        rules="required|positivePhone"
                        name="celular"
                      >
                        <b-form-input
                          v-model="cellphone"
                          v-mask="'## #####-####'"
                          class="cols-12"
                          placeholder="Celular com DDD"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form>
            </b-col>
          </b-row>
        </div>
        <b-row class="mt-2">
          <b-col class="text-right">
            <b-button
              variant="outline"
              class="mr-1 text-primary"
              :disabled="disable"
              @click="cancel"
            >
              {{ cancelTitle }}
            </b-button>
            <b-button
              variant="primary"
              :disabled="disable"
              @click="save"
            >
              <b-spinner
                v-show="loading"
                small
              />
              Responder agora
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BModal, BContainer, BRow, BCol, BButton, BFormGroup, BFormTextarea, BImg, BFormCheckbox, BFormInput, BSpinner, BForm,
} from 'bootstrap-vue'
import { required } from 'vee-validate/dist/rules'
import {
  ValidationProvider, ValidationObserver, localize, extend,
} from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BModal,
    BContainer,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormTextarea,
    BImg,
    BFormCheckbox,
    BFormInput,
    BSpinner,
    BForm,
    ValidationProvider,
    ValidationObserver,

  },
  data() {
    return {
      selectedOption: null,
      options: [
        {
          value: 47,
          text: 'Péssima',
          image: [
            require('@/assets/images/icons/cuco-pessimo.svg'),
            require('@/assets/images/icons/cuco-pessimo-full.svg'),
          ],
        },
        {
          value: 48,
          text: 'Ruim',
          image: [
            require('@/assets/images/icons/cuco-ruim.svg'),
            require('@/assets/images/icons/cuco-ruim-full.svg'),
          ],
        },
        {
          value: 49,
          text: 'Regular',
          image: [
            require('@/assets/images/icons/cuco-regular.svg'),
            require('@/assets/images/icons/cuco-regular-full.svg'),
          ],
        },
        {
          value: 50,
          text: 'Boa',
          image: [
            require('@/assets/images/icons/cuco-bom.svg'),
            require('@/assets/images/icons/cuco-bom-full.svg'),
          ],
        },
        {
          value: 51,
          text: 'Ótima',
          image: [
            require('@/assets/images/icons/cuco-otimo.svg'),
            require('@/assets/images/icons/cuco-otimo-full.svg'),
          ],
        },
      ],
      maxCharacters: 500,
      comment: '',
      newFeatures: false,
      cancelTitle: 'Responder depois',
      show: false,
      disable: false,
      loading: false,
      name: '',
      cellphone: '',

    }
  },
  watch: {
    // eslint-disable-next-line func-names
    '$store.state.survey.pcx_csat': async function () {
      if (this.$store.state.survey.pcx_csat) {
        this.initialize()
      }
    },
  },
  mounted() {
    localize(this.$store.getters['cuido/locale'])
    extend('requiredScore', {
      ...required,
      validate: () => {
        if (!this.selectedOption) {
          return 'O campo nota é obrigatório'
        }
        return true
      },
    })
    extend('maxCaracters', {
      ...required,
      validate: () => {
        if (this.comment.length > 500) {
          return 'O campo nota é obrigatório'
        }
        return true
      },
    })
    extend('positivePhone', async value => {
      const newValue = value.replace(/\D/g, '')
      const regex = new RegExp(/^\d{2}[789]\d{8}$/)

      if (newValue.length === 11 && regex.test(newValue)) {
        return true
      }
      return 'Insira um celular com o DDD + número.'
    })
  },
  methods: {
    selectedScore(option) {
      this.selectedOption = option
      this.$refs.scoreInput.validate()
    },
    imageUrl(option) {
      return this.selectedOption === option.value ? option.image[1] : option.image[0]
    },
    save() {
      this.$refs.scoreRules.validate().then(success => {
        if (success) {
          this.disable = true
          this.loading = true

          const payload = {
            survey_id: this.id,
            unsubscribed: false,
            text_answer: this.comment === '' ? null : this.comment,
            survey_option_ids: [this.selectedOption],
            workshop: (this.name && this.cellphone) ? { name: this.name, phone: `55${this.cellphone.replace(/\D/g, '')}` } : null,

          }
          this.$store.dispatch('survey/answer', payload)
            .then(() => {
              if (localStorage.getItem(`replySurveyCSAT-${window.btoa(this.$store.state.login.email)}`)) {
                localStorage.removeItem(`replySurveyCSAT-${window.btoa(this.$store.state.login.email)}`)
              }
              this.exit()
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Resposta enviada com sucesso!',
                  text: 'Sua opinião nos ajuda a melhorar a plataforma para todas as pessoas.',
                  size: 'sm',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })
            })
            .catch(() => {
              this.exit()
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Ops... Algo deu errado, tente novamente',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
            .finally(() => {
              this.$store.commit('survey/UPDATE_PCX_CSAT', false)
              this.disable = false
              this.loading = false
            })
        }
      })
    },
    cancel(bvModalEvt) {
      if (bvModalEvt) {
        bvModalEvt.preventDefault()
      }

      const replyBariatricSurvey = localStorage.getItem(`replySurveyCSAT-${window.btoa(this.$store.state.login.email)}`)

      if (replyBariatricSurvey) {
        this.$store.dispatch('survey/answer', { survey_id: this.id, unsubscribed: true })
          .then(() => {
            localStorage.removeItem(`replySurveyCSAT-${window.btoa(this.$store.state.login.email)}`)
          })
      } else {
        const currentTimestamp = Date.now()
        localStorage.setItem(`replySurveyCSAT-${window.btoa(this.$store.state.login.email)}`, currentTimestamp)
      }
      return this.exit()
    },
    initialize() {
      this.$store.dispatch('survey/show', { identifier: 'pcx_csat_cuido', showUnsubscribed: true, maxAnsweredDays: 90 })
        .then(response => {
          const { data } = response.data
          if (Object.keys(data.survey_info).length > 0) {
            this.id = data.survey_info.id
            return this.checkEligibility()
          }
          return this.exit()
        })
    },
    checkEligibility() {
      const currentTimestamp = Date.now()
      const twentyFourHoursInMs = 24 * 60 * 60 * 1000
      const replySurvey = Number(localStorage.getItem(`replySurveyCSAT-${window.btoa(this.$store.state.login.email)}`))

      if (replySurvey) {
        if ((currentTimestamp - replySurvey) >= twentyFourHoursInMs) {
          this.cancelTitle = 'Não perguntar mais'
          return this.openModal()
        }
        return this.exit()
      }
      return this.openModal()
    },
    exit() {
      this.$store.commit('survey/UPDATE_PCX_CSAT', false)
      this.show = false
    },
    openModal() {
      this.show = true
    },
  },
}
</script>
<style>
[dir=ltr] .btn-group [class*=btn-]:not([class*=btn-outline-]):not([class*=btn-flat-]):not([class*=btn-gradient-]):not([class*=btn-relief-]) {
  border: none !important;
}
</style>
