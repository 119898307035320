<template>
  <div>
    <b-modal
      :id="modalId"
      size="md"
      no-close-on-backdrop
      no-close-on-esc
      centered
      hide-footer
      hide-header
      hide-header-close
    >
      <b-container>
        <b-row>
          <b-col class="text-center mb-2">
            <feather-icon
              :icon="iconHeader"
              size="90"
              :stroke="iconHeaderColor"
              stroke-width="1.5"
            />
          </b-col>
        </b-row>
        <b-row v-if="title">
          <b-col class="text-center">
            <h4><strong>{{ title }}</strong></h4>
          </b-col>
        </b-row>
        <b-row v-if="description">
          <b-col class="text-center">
            <p>{{ description }} <br> {{ belowDescription }}</p>
          </b-col>
        </b-row>
        <b-row v-if="smallDescription">
          <b-col class="text-center">
            <p><small>{{ smallDescription }}</small></p>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-center mt-2">
            <b-button
              v-if="cancelLabel"
              variant="outline"
              class="mr-1 text-primary"
              @click="cancel"
            >
              {{ cancelLabel }}
            </b-button>
            <b-button
              v-if="okLabel"
              variant="primary"
              @click="ok"
            >
              {{ okLabel }}
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>
<script>
import {
  BModal, BContainer, BRow, BCol, BButton,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BModal,
    BContainer,
    BRow,
    BCol,
    BButton,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  props: {
    iconHeader: {
      type: String,
      default: '',
    },
    iconHeaderColor: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    belowDescription: {
      type: String,
      default: null,
    },
    smallDescription: {
      type: String,
      default: null,
    },
    cancelLabel: {
      type: String,
      default: null,
    },
    okLabel: {
      type: String,
      default: null,
    },
    modalId: {
      type: String,
      default: 'modal-id',
    },

  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    ok() {
      this.$emit('ok')
    },
  },
}
</script>
<style lang="">

</style>
