<template>
  <div>
    <b-modal
      id="modal-recharge-stock"
      size="lg"
      no-close-on-backdrop
      centered
      header-bg-variant="light"
      title="Solicitar reposição de itens"
      @show="fetchStock"
      @ok="next"
      @close="closeModal"
    >
      <template #modal-header="{ close }">
        <h4 class="mt-1">
          Solicitar reposição de itens
        </h4>
        <b-button
          variant="outline"
          class="ml-1"
          size="sm"
          @click="close()"
        >
          <feather-icon
            icon="XIcon"
            size="20"
            stroke="gray"
          />
        </b-button>
      </template>
      <template #modal-footer="{ close, ok }">
        <b-button
          variant="outline-danger"
          @click="close"
        >
          Cancelar
        </b-button>
        <b-button
          :disabled="!medicationSelected.length > 0"
          variant="primary"
          @click="ok"
        >
          Solicitar
        </b-button>
      </template>
      <b-row>
        <b-col>
          <b-form-checkbox
            name="check-button"
            button
            button-variant="link"
            @change="toggleAll"
          >
            {{ medicationSelected.length > 0 ? 'Excluir seleção': 'Selecionar todos' }}
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row
        class="mt-2 ml-0"
      >
        <b-col>
          <b-table
            ref="refStockRechargeTable"
            class="srcroll-hide"
            sticky-header
            :items="medications"
            responsive
            :fields="tableColumns"
            primary-key="id"
            thead-class="d-none"
          >
            <template #cell(select)="data">
              <div class="text-nowrap">
                <b-form-checkbox
                  v-model="itemsSelected"
                  :value="data.item"
                  :disabled="!isEmpty(data.item.item_info.recharge_info)"
                  @change="updateMedicationSelected"
                />
              </div>
            </template>
            <template #cell(item)="data">
              <b-row>
                <b-col>
                  <span>{{ data.item.item_info.name }}</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <span class="font-weight-bold">Liberados: {{ data.item.sent_quantity }} caixas | Disponível {{ data.item.current_quantity }} caixas</span>
                </b-col>
              </b-row>
            </template>
            <template #cell(requested)="data">
              <b-badge
                v-if="!isEmpty(data.item.item_info.recharge_info)"
                style="background-color: #cceaf8;"
              >
                <span style="color: #1fa1de">Solicitado: {{ formatDate(data.item.item_info.recharge_info.requested_at) }}</span>
              </b-badge>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BTable, BBadge, BFormCheckbox,
} from 'bootstrap-vue'
import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BTable,
    BBadge,
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      medications: [],
      tableColumns: [
        { key: 'select' },
        { key: 'item' },
        { key: 'requested' },
      ],
      itemsSelected: this.$store.state.stock.stockRechargeMedicationSelected,
    }
  },
  computed: {
    medicationSelected() {
      return this.$store.state.stock.stockRechargeMedicationSelected
    },
  },
  methods: {
    async fetchStock() {
      if (this.medicationSelected.length > 0) {
        return
      }
      this.medications = []
      store
        .dispatch('stock/listMedication', {
          q: '',
        })
        .then(response => {
          const { stocks_info } = response.data.data
          // eslint-disable-next-line
          this.medications = stocks_info.filter(item => item.current_quantity == 0)
          this.itemsSelected = this.$store.state.stock.stockRechargeMedicationSelected
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ops... Algo deu errado, tente novamente',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    isEmpty(obj) {
      return Object.keys(obj).length === 0
    },
    toggleAll() {
      if (this.medicationSelected.length === 0) {
        const medicationSelected = this.medications.filter(item => {
          if (this.isEmpty(item.item_info.recharge_info)) {
            return item
          }
          return false
        })
        return this.updateMedicationSelected(medicationSelected)
      }
      return this.updateMedicationSelected([])
    },
    formatDate(value) {
      if (value === null) {
        return ''
      }
      return moment(value).format('DD/MM/YY')
    },
    next() {
      this.$emit('next')
    },
    updateMedicationSelected(items) {
      this.itemsSelected = items
      this.$store.commit('stock/UPDATE_STOCK_RECHARGE_MEDICATION_SELECTED', items)
    },
    closeModal() {
      this.updateMedicationSelected([])
      this.medications = []
    },
  },
}
</script>

<style scoped>
/* ===== Scrollbar CSS ===== */
  /* Firefox */
  .srcroll-hide {
    scrollbar-width: auto;
    scrollbar-color: #ffffff #ffffff;
  }

  /* Chrome, Edge, and Safari */
  .srcroll-hide::-webkit-scrollbar {
    width: 11px;
  }

  .srcroll-hide::-webkit-scrollbar-track {
    background: #ffffff;
  }

  .srcroll-hide::-webkit-scrollbar-thumb {
    background-color: #ffffff;
    border-radius: 10px;
    border: 3px solid #ffffff;
  }
</style>
