<template>
  <b-modal
    id="stock-modal"
    ref="stock-modal"
    hide-header
    hide-footer
    body-class="stock-modal-body"
    centered
    size="lg"
  >
    <div class="stock-modal-header">
      <div class="stock-modal-info">
        <img src="@/assets/images/qrcode/logo-primeira-caixa.png">
        <h2>Ter acesso à Primeira Caixa gratuita é simples!</h2>
      </div>
      <div>
        <img src="@/assets/images/pages/pacient.png">
      </div>
    </div>
    <div class="stock-modal-grid">
      <div class="stock-modal-grid-item">
        <img
          src="@/assets/images/qrcode/qrcode-purple.png"
          style="width: 45%"
        >
        <p>Cadastre-se acessando <a href="https://primeiracaixa.com.br/">primeiracaixa.com.br</a> ou escaneando o QR code.</p>
      </div>
      <div class="stock-modal-grid-item">
        <img src="@/assets/images/qrcode/pencil.png">
        <p>Para continuar, insira seus dados do Conselho Profissional e selecione o item indicado.</p>
      </div>
      <div class="stock-modal-grid-item">
        <img
          src="@/assets/images/qrcode/pin.png"
          class="mt-3"
        >
        <p>Com o cadastro finalizado, o paciente pode verificar a farmácia Raia ou Drogasil mais próxima com o item em estoque.</p>
      </div>
    </div>
    <div class="stock-modal-buttons">
      <button
        type="button"
        @click.prevent="close"
      >
        <u>Fechar</u>
      </button>
      <button @click.prevent="printQRCode">
        Imprimir QR Code
      </button>
    </div>
  </b-modal>
</template>

<script>
import qrcodeFunction from '@/utils/functions'

export default {
  name: 'StockModal',
  methods: {
    close() {
      this.$refs['stock-modal'].hide()
    },
    printQRCode() {
      const { print } = qrcodeFunction()
      print()
    },
  },
}
</script>

<style>
.stock-modal-body {
  padding: 0;
}

.stock-modal-header {
  background: #F3F0F7 0% 0% no-repeat padding-box;
  border-radius: 6px 6px 0px 0px;
  padding: 20px 64px 0 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.stock-modal-info {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
}

.stock-modal-info img {
  width: 70%;
}

.stock-modal-info h2 {
  text-align: left;
  color: #5A607F;
  letter-spacing: 0.1px;
  font: normal normal bold 24px/32px sans-serif;
}

.stock-modal-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  gap: 30px;
  padding: 40px 20px;
}

@media (max-width: 1000px) {
  .stock-modal-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

.stock-modal-grid-item {
  text-align: center;
}

.stock-modal-grid-item p {
  margin-top: 15px;
  color: #131523;
  font-weight: bold;
  letter-spacing: 0.09px;
}

.stock-modal-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  margin-bottom: 40px;
}

.stock-modal-buttons > button:first-child {
  color: #3279EB;
  background: none;
  border: none;
  font-weight: bold;
  font-size: 16px;
}

.stock-modal-buttons > button:last-child {
  color: #3279EB;
  font-weight: bold;
  font-size: 16px;
  background: none;
  border: 2px solid #3279EB;
  border-radius: 6px;
  padding: 8px 18px;
}

@media (max-width: 720px) {
  .stock-modal-header {
    flex-direction: column;
    padding: 10px 20px;
  }

  .stock-modal-info {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .stock-modal-info img {
    width: 40%;
  }

  .stock-modal-info h2 {
    font-size: 18px;
    line-height: 24px;
  }
}
</style>
