<template>

  <div>
    <b-row v-if="pacientRequest && !hasPCXProAlert">
      <b-col
        md="8"
        sm="12"
        no-body
        class="mb-0 d-flex"
      >
        <b-card v-if="hasPagesInfo">
          <div class="m-2">
            <b-row class="align-items-center">
              <b-col sm="12">
                <div class="d-flex align-items-center">
                  <img
                    v-if="pagesInfo.image"
                    :src="pagesInfo.image"
                    width="100rem"
                    class="mr-1"
                  >
                  <b-col>
                    <b-row>
                      <h3 class="text-gray-800">
                        {{ pagesInfo.title }}
                      </h3>
                    </b-row>
                    <b-row>
                      <p class="m-0">
                        {{ pagesInfo.description }}
                      </p>
                    </b-row>
                  </b-col>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-card>
        <b-card class="w-100">
          <div class="m-2">
            <b-row class="align-items-center">
              <b-col :sm="hasRechargeStock ? '8' : '12'">
                <div class="d-flex align-items-center">
                  <img
                    :src="logoProgram"
                    width="57rem"
                    class="mr-1"
                  >
                  <h3 class="text-gray-800 m-0">
                    Dados do estoque ({{ nameProgram }})
                  </h3>
                </div>
              </b-col>
              <b-col
                v-if="hasRechargeStock"
                class="d-flex align-items-center "
                :class="{'justify-content-end': !sm, 'justify-content-center mt-2': sm}"
              >
                <b-button
                  class="mr-3"
                  size="sm"
                  variant="primary"
                  :disabled="(!hasStockEmpty)"
                  @click="openRechargeModal()"
                >
                  Solicitar recarga
                </b-button>
              </b-col>
            </b-row>
          </div>
          <b-table
            ref="refStockListTable"
            class="position-relative"
            :items="fetchStock"
            responsive
            :fields="tableColumnsMin"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            :empty-text="
              searchQuery.length === 0
                ? 'O estoque virtual ainda não está liberado para você.'
                : 'Nenhum item encontrado.'
            "
            :sort-desc.sync="isSortDirDesc"
          >

            <template #table-busy>
              <b-skeleton-table
                :rows="0"
                :columns="4"
              />
            </template>
            <!-- Column: Medication -->
            <template #cell(Item)="data">
              <div class="text-nowrap p-0">
                <span class="align-text-top text-capitalize">
                  <span v-if="data.item.medication_quantity > 1">
                    {{ data.item.medication_quantity }} UNIDADES
                  </span>
                  <span
                    :class="data.item.item_info.item_childs_info.length > 0 ? 'font-weight-bolder' : '' "
                  >
                    {{ data.item.item_info.name }}
                  </span>
                  <div v-if="data.item.item_info.type === 'KIT'">
                    <div
                      v-for="item in data.item.item_info.item_childs_info"
                      :key="item.id"
                    >
                      <b-row class="ml-0 mt-1">
                        <div class="d-flex align-items-center">
                          <b-img
                            class="mr-1"
                            :src="iconType(item.type)"
                            width="21"
                            height="21"
                          />
                          <span>{{ item.name }} {{ itemQuantityDescription(item) }}</span>
                        </div>
                      </b-row>
                    </div>
                  </div>

                </span>
                <!-- <br><span class="badge badge-danger">{{ data.item.medication_tag }}</span> -->
              </div>
            </template>

            <!-- Column: Released -->
            <template #cell(Liberados)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{ data.item.sent_quantity }}</span>
              </div>
            </template>

            <!-- Column: Available -->
            <template #cell(Disponíveis)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{ data.item.current_quantity }}</span>
              </div>
            </template>

            <!-- Column: Laboratory -->
            <template #cell(Laboratório)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{ data.item.laboratory }}</span>
              </div>
            </template>
          </b-table>
        </b-card>
      </b-col>
      <b-col
        md="4"
        sm="12"
      >
        <b-card>
          <b-row>
            <b-col
              md="6"
              sm="6"
            >
              <p class="font-weight-bold mt-1 text-black">
                Solicite que o paciente tire foto do QR Code ao lado ou acesse o site <a href="https://primeiracaixa.com.br/">primeiracaixa.com.br</a> para fazer o cadastro.
              </p>
              <b-button
                variant="outline-primary"
                size="sm"
                @click="printQrCode"
              >
                Imprimir QR Code
              </b-button>
            </b-col>
            <b-col
              sm="6"
              md="6"
            >
              <img
                src="@/assets/images/qrcode/qr-code-primeira-caixa.png"
                width="100%"
              >
            </b-col>
          </b-row>
          <div class="text-center mt-1">
            <b-button
              v-b-modal.stock-modal
              variant="link"
            ><u>Como funciona o Primeira Caixa?</u></b-button>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <div v-else-if="!hasPCXProAlert">
      <b-card v-if="hasPagesInfo">
        <div>
          <b-row class="align-items-center">
            <b-col sm="12">
              <div class="d-flex align-items-center">
                <img
                  v-if="pagesInfo.image"
                  :src="pagesInfo.image"
                  width="100rem"
                  class="mr-1"
                >
                <b-col>
                  <b-row>
                    <h3 class="text-gray-800">
                      {{ pagesInfo.title }}
                    </h3>
                  </b-row>
                  <b-row>
                    <p class="m-0">
                      {{ pagesInfo.description }}
                    </p>
                  </b-row>
                </b-col>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-card>

      <b-card
        no-body
        class="mb-0"
      >
        <div class="m-2">
          <b-row class="align-items-center">
            <b-col sm="7">
              <div class="d-flex align-items-center">
                <img
                  :src="logoProgram"
                  width="57rem"
                  class="mr-1"
                >
                <h3 class="text-gray-800 m-0">
                  Dados do estoque ({{ nameProgram }})
                </h3>
              </div>
            </b-col>
            <b-col
              class="d-flex align-items-center "
              :class="{'justify-content-end': !sm, 'justify-content-center mt-2': sm}"
            >
              <b-button
                v-if="hasRechargeStock"
                class="mr-3"
                size="sm"
                variant="primary"
                :disabled="!hasStockEmpty"
                @click="openRechargeModal()"
              >
                Solicitar recarga
              </b-button>
              <b-button
                size="sm"
                variant="outline-primary"
                @click="history()"
              >
                Histórico de liberações
              </b-button>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col
              :cols="sm ? 12 : 6"
              md="6"
              sm="12"
            >
              <b-input-group>
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  v-model="searchQuery"
                  type="text"
                  placeholder="Busque por um item"
                />
              </b-input-group>
            </b-col>
            <b-col
              v-if="!sm"
              cols="6"
              md="6"
              class="d-flex align-items-center justify-content-end"
              style="grid-area: teste"
            >
              <label>Mostrar</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>entradas</label>
            </b-col>
          </b-row>

        </div>

        <b-table
          ref="refStockListTable"
          class="position-relative"
          :items="fetchStock"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          :empty-text="
            searchQuery.length === 0
              ? 'O estoque virtual ainda não está liberado para você.'
              : 'Nenhum item encontrado.'
          "
          :sort-desc.sync="isSortDirDesc"
        >

          <template #table-busy>
            <b-skeleton-table
              :rows="0"
              :columns="4"
            />
          </template>
          <!-- Column: Medication -->
          <template #cell(Item)="data">
            <div class="text-nowrap">
              <span class="align-text-top">
                <span v-if="data.item.item_info.quantity > 1">
                  {{ data.item.item_info.quantity }} {{ isUppercase(data.item.item_info.name) ? 'UNIDADES' : 'unidades' }}
                </span>
                <span
                  :class="data.item.item_info.item_childs_info.length > 0 ? 'font-weight-bolder' : '' "
                >
                  {{ data.item.item_info.name }}
                </span>
                <div v-if="data.item.item_info.type === 'KIT'">
                  <div
                    v-for="item in data.item.item_info.item_childs_info"
                    :key="item.id"
                  >
                    <b-row class="ml-0 mt-1">
                      <b-img
                        class="mr-1"
                        :src="iconType(item.type)"
                        width="21"
                        height="21"
                      />
                      <span>{{ item.name }} {{ itemQuantityDescription(item) }}</span>
                    </b-row>
                  </div>
                </div>
              </span>
              <!-- <br> <span class="badge badge-danger">{{ data.item.medication_tag }}</span> -->
            </div>
          </template>

          <!-- Column: Released -->
          <template #cell(Liberados)="data">
            <div class="text-nowrap">
              <span class="align-text-top text-capitalize">{{ data.item.sent_quantity }}</span>
            </div>
          </template>

          <!-- Column: Available -->
          <template #cell(Disponíveis)="data">
            <div class="text-nowrap">
              <span class="align-text-top text-capitalize">{{ data.item.current_quantity }}</span>
            </div>
          </template>

          <!-- Column: Select -->
          <template #cell(Selecionar)="data">
            <div class="text-nowrap">
              <b-form-checkbox
                v-model="medicationSelected"
                :value="data.item"
                :disabled="data.item.current_quantity == 0 ? true : false"
              />
            </div>
          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <!-- Pagination -->
            <div class="custom-grid">
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
                style="grid-area: pagination"
              >

                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalStock"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </b-col>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
                style="grid-area: btn"
              >
                <b-button
                  variant="primary"
                  :disabled="medicationSelected.length === 0 ? true : false"
                  @click="send()"
                >
                  Continuar
                </b-button>
              </b-col>
              <b-col
                v-if="sm"
                cols="12"
                class="d-flex align-items-center justify-content-center"
                style="grid-area: teste"
              >
                <label>Mostrar</label>
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entradas</label>
              </b-col>
            </div>
          </b-row>
        </div>
      </b-card>
    </div>
    <transition name="fade">
      <b-card
        v-if="pcxProLink"
        class="card-custom"
      >
        <b-card-body
          class="d-flex flex-wrap "
        >
          <b-img
            v-if="!sm"
            src="@/assets/images/pages/pcx-bg.svg"
            fluid
            class="svg-background"
            alt="Imagem SVG"
          />
          <b-img
            v-else
            src="@/assets/images/pages/pcx-bg-mobile.svg"
            fluid
            class="svg-background"
            alt="Imagem SVG"
          />
          <PCXProCard :pcx-pro-link="pcxProLink" />
        </b-card-body>
      </b-card>
    </transition>
    <div v-if="!pcxProLink">
      <stock-help />
      <stock-modal />
      <stock-CSAT-modal />
      <stock-recharge-modal @next="openModalConfirmation" />
      <basic-stock-modal
        modal-id="confirm-modal"
        icon-header="AlertCircleIcon"
        icon-header-color="#83808e"
        :description="descriptionConfirmModal"
        :below-description="belowDescriptionConfirmModal"
        :small-description="selectedItemsRecharge"
        cancel-label="Voltar"
        ok-label="Confirmar"
        @ok="submitStockRecharge"
        @cancel="openRechargeModal"
      />
      <basic-stock-modal
        modal-id="success-modal"
        icon-header="CheckCircleIcon"
        icon-header-color="#21d59b"
        title="Solicitação enviada com sucesso!"
        :description="descriptionSuccessModal"
        ok-label="Ok"
        @ok="closeSuccessModal"
      />
    </div>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable,
  BPagination, VBModal, BFormInput, BFormCheckbox, BInputGroup,
  BInputGroupPrepend, BButton, BImg, BSkeletonTable, BCardBody,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { mapState } from 'vuex'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import StockModal from '@/views/pages/stock/StockModal.vue'
import qrcodeFunction from '@/utils/functions'
import { breakpointsBootstrapV5, useBreakpoints } from '@vueuse/core'
import BasicStockModal from '@/layouts/components/stock/BasicStockModal.vue'
import useStokckList from './useStockList'
import StockHelp from './Stock-help.vue'
import StockCSATModal from './StockCSATModal.vue'
import StockRechargeModal from './StockRechargeModal.vue'
// import PCXProModal from './pcx-pro/PCXProModal.vue'
import PCXProCard from './pcx-pro/PcxProCard.vue'

export default {
  components: {
    StockModal,
    BasicStockModal,
    // StockListFilters,

    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BButton,
    BImg,
    BSkeletonTable,
    BCardBody,

    vSelect,
    StockHelp,
    StockCSATModal,
    StockRechargeModal,
    // PCXProModal,
    PCXProCard,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },

  directives: {
    'b-modal': VBModal,
    Ripple,
  },

  setup() {
    const isAddNewStockSidebarActive = ref(false)
    const breakpoints = useBreakpoints(breakpointsBootstrapV5)
    const sm = breakpoints.smallerOrEqual('sm')

    const {
      fetchStock,
      tableColumns,
      perPage,
      currentPage,
      totalStock,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      tableColumnsMin,
      refStockListTable,
      refetchData,
      hasStockEmpty,

      // UI
      resolveStockRoleVariant,
      resolveStockRoleIcon,
      resolveStockStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useStokckList()

    return {

      // Sidebar
      isAddNewStockSidebarActive,

      fetchStock,
      tableColumns,
      perPage,
      currentPage,
      totalStock,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refStockListTable,
      refetchData,
      tableColumnsMin,
      hasStockEmpty,
      // UI
      resolveStockRoleVariant,
      resolveStockRoleIcon,
      resolveStockStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      sm,
    }
  },

  data() {
    return {
      nameProgram: '',
      medications: [],
      medicationSelected: [],
      logoProgram: '',
      agreement: {},
      pagesInfo: {},
      pcxProLink: '',
    }
  },
  computed: {
    showHelp() {
      return this.$store.state.breadcrumb.showHelp
    },
    pacientRequest() {
      return this.$store.state.stock.program?.pacient_request
    },

    hasPagesInfo() {
      return Object.keys(this.$store.getters['stock/pagesInfoProgram']).length !== 0
    },
    hasRechargeStock() {
      return this.$store.getters['stock/rechargeStock']
    },
    descriptionConfirmModal() {
      return `Sua solicitação de recarga será enviada
       ao responsável do programa ${this.$store.getters['stock/nameProgram']}.`
    },
    belowDescriptionConfirmModal() {
      return `Você confirma a solicitação ${this.itemsLabel}?`
    },
    descriptionSuccessModal() {
      return `Aguarde até 7 dias para verificar se ela foi atendida pelo
       responsável do programa ${this.$store.getters['stock/nameProgram']}.`
    },
    itemsLabel() {
      return this.$store.state.stock.stockRechargeMedicationSelected.length > 1 ? 'dos seguintes itens' : 'do seguinte item'
    },
    selectedItemsRecharge() {
      return this.$store.state.stock.stockRechargeMedicationSelected.map(item => item.item_info.name)
        .join('; ')
    },
    hasPCXProAlert() {
      return this.$store.state.stock.program?.active_pcxpro
    },
  },

  watch: {
    showHelp(value) {
      if (value === true) {
        this.$bvModal.show('modal-center')
      }
    },
    fetchStock() {
      this.$store.commit('stock/UPDATE_PROGRAM', this.fetchStock)
    },
  },

  mounted() {
    if (this.hasPCXProAlert) {
      this.$store.dispatch('login/getAuthCode')
        .then(response => {
          if (response.data?.data?.auth_code_info?.code) {
            this.pcxProLink = `${process.env.VUE_APP_PCX_PRO_URL}auth/${response.data.data.auth_code_info.code}`
            this.$bvModal.show('pcx-pro-modal')
          }
        })
    }
    this.$store.commit('breadcrumb/UPDATE_SHOW_HELP', false)
    this.$store.commit('survey/UPDATE_PCX_CSAT', Date.now())

    this.nameProgram = this.$store.getters['stock/nameProgram']
    this.logoProgram = this.$store.getters['stock/logoProgram']
    this.pagesInfo = this.$store.getters['stock/pagesInfoProgram']

    this.checkAgreement()
  },

  methods: {
    history() {
      this.$router.push('stock-history')
    },
    send() {
      this.$store.commit('stock/UPDATE_MEDICATION_SELECTED', this.medicationSelected)
      this.$router.push('stock-send')
    },
    checkAgreement() {
      const agreed = this.$store.getters['stock/agreedProgram']
      if (!agreed) {
        this.$store.dispatch('stock/getAgreement')
          .then(response => {
            this.agreement = response.data.data.agreement_info

            this.$swal({
              title: '',
              html: `<p><a href="${this.agreement.file_path}" target="_blank" title="Acesse aqui">Acesse aqui</a> o regulamento do ${this.agreement.name}.</p> <p>Para iniciar sua participação no Programa, é necessário ler e aceitar o regulamento.</p>`,
              icon: 'warning',
              iconColor: '#5A607F',
              showCancelButton: true,
              confirmButtonText: 'Aceitar',
              cancelButtonText: 'Voltar',
              customClass: {
                popup: 'p-2',
                actions: 'pb-1',
                confirmButton: 'btn btn-primary mx-1',
                cancelButton: 'btn btn-flat-primary mx-1',
                loader: 'custom-sweetalert2-loader w-auto h-auto',
              },
              reverseButtons: true,
              allowOutsideClick: false,
              allowEscapeKey: false,
              buttonsStyling: false,
              showLoaderOnConfirm: true,
              loaderHtml: '<button disabled class="btn btn-primary mx-1"><span aria-hidden="true" class="spinner-border spinner-border-sm"></span> Aceitar</button>',
              preConfirm: () => this.saveAgree(),
            }).then(result => {
              if (result.isDismissed) {
                this.$router.push('stock')
              }
            })
          })
          .catch(() => {
            this.$swal({
              title: '',
              html: '<p>Para iniciar sua participação no Programa, é necessário ler e aceitar o regulamento.</p>',
              icon: 'warning',
              iconColor: '#5A607F',
              showCancelButton: true,
              cancelButtonText: 'Voltar',
              showConfirmButton: false,
              customClass: {
                popup: 'p-2',
                cancelButton: 'btn btn-flat-primary mx-1',
              },
              allowOutsideClick: false,
              allowEscapeKey: false,
              buttonsStyling: false,
            }).then(() => {
              this.$router.push('stock')
            })
          })
      }
    },
    async saveAgree() {
      const { client_info } = this.$store.getters['register/data']
      const agreementInfo = {
        agreement_info: {
          agreement_id: this.agreement.id,
          agree: true,
        },
        client_info,
      }
      await this.$store.dispatch('stock/saveAgree', { agreementInfo })
        .then(() => {
          this.$store.commit('stock/UPDATE_AGREED_PROGRAM', true)

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Regulamento aceito com sucesso',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ops... Algo deu errado, tente novamente',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          this.$router.push('stock')
        })
    },
    printQrCode() {
      const { print } = qrcodeFunction()
      print()
    },

    isUppercase(str) {
      return str === str.toUpperCase()
    },

    openRechargeModal() {
      this.$bvModal.hide('confirm-modal')
      this.$bvModal.show('modal-recharge-stock')
    },
    openModalConfirmation() {
      this.$bvModal.show('confirm-modal')
    },
    submitStockRecharge() {
      this.$bvModal.hide('confirm-modal')

      this.$store.dispatch('stock/stockRecharge')
        .then(() => {
          this.$store.commit('stock/UPDATE_STOCK_RECHARGE_MEDICATION_SELECTED', [])
          this.$bvModal.show('success-modal')
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ops... Algo deu errado, tente novamente',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    closeSuccessModal() {
      this.$bvModal.hide('success-modal')
    },
    itemQuantityDescription(item) {
      return item.type === 'SERVICE' ? `(${item.quantity} ${item.quantity > 1 ? 'Aplicações' : 'Aplicação'})` : `(${item.quantity} ${item.quantity > 1 ? 'Unidades' : 'Unidade'})`
    },
    iconType(type) {
      if (type === 'MEDICATION') {
        return require('@/assets/images/icons/icone-medicamento.svg')
      }
      if (type === 'SERVICE') {
        return require('@/assets/images/icons/icone-servico.svg')
      }
      return require('@/assets/images/icons/icone-produto.svg')
    },
  },

}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.custom-sweetalert2-loader {
  animation: none;
  border: 0;
}

.custom-grid {
  width: 100%;
  display: flex;
}

@media screen and (max-width: 620px) {
  .custom-grid {
    margin-top: 25px;
    display: grid;
    grid-template-areas: 'btn' 'pagination' 'teste';
    gap: 24px;
  }
}

.card-custom {
    border-radius: 16px;
  }
.svg-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
}
</style>
