export default function qrcodeFunction() {
  function print() {
    const link = document.createElement('a')
    link.href = 'https://assets.cuco.health/qrcodes/qrcode-primeira-caixa.pdf'
    link.setAttribute('download', 'qrcode-primeira-caixa.pdf')
    link.setAttribute('target', '_blank')
    document.body.appendChild(link)
    link.click()
  }

  return {
    print,
  }
}
